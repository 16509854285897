/**
 * AND MAIN SUB NAVIGATION
 */
.mobile-navigation {
    background-color: $color-white-grey;
    position: fixed;
    left: 0;
    right: 0;
    overflow-y: auto;
    box-shadow: 0 0 10px 0 rgba($grey, 0.3);
    margin: 0 auto;
    z-index: 220;
    transition: transform 0.4s ease-out;
    transform: translate(-120%, 0);
    height: 770px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 60px);
    overflow-x: hidden;
    max-width: calc(2200px - 60px);
    @include media-breakpoint-down(lg) {
        height: auto;
    }
    @include media-breakpoint-up(sm) {
        top: 30px;
    }
    @include media-breakpoint-down(sm) {
        top: $extended-header-height-mobile;
        width: 100%;
        height: calc(100% - 65px);

    }

    &__close-helper {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;
        z-index: 219;
    }

    .logo-wrapper {
        /*height: ($extended-header-height-desktop - ($header-space * 2));
        @include media-breakpoint-down(xs) {
          height: ($extended-header-height-mobile - ($header-space * 2));
        }*/
        padding-left: 110px;
        margin-top: 30px;
        @include media-breakpoint-down(lg) {
            padding-left: 0px;
        }
        a {
            color: transparent;
        }
        a:first-child {
            margin-right: 35px;
        }
        &__logo {
            position: relative;
            /*top: 50%;
            transform: translate(0, -50%); */
            max-height: 100%;
            width: auto;
        }
    }

    img.logo-wrapper__logo {
        &--closed {
            display: none;
        }
        &--open {
            display: inline-block;
        }
    }

    .top-navigation-outer-wrapper {
        max-width: 50%;
        width: 100%;
        @media (max-width: 1399px) {
            max-width: 100%;
            width: 100%;
        }
        .top-navigation-wrapper {
            margin-right: 130px;
            margin-top: 45px;
            @media (max-width: 1399px) {
                margin-right: 40px;
                margin-top: 30px;
            }

            @include media-breakpoint-down(lg) {

            }
            @include media-breakpoint-down(xs) {
                margin-right: 0px;
            }
            .search-bar {
                width: 400px;
                display: inline-block;
                @include media-breakpoint-down(sm) {
                    width: 300px;
                }

                form {
                    width: 100%;
                }

                input {
                    background: transparent;
                    padding: 5px 10px;
                    border-width: 0 0 1px 0;
                    border-color: $body-color;
                    border-style: solid;
                    color: $body-color;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:focus {
                        outline: 0;
                    }
                }

                button {
                    border: none;

                    svg {
                        color: $body-color;
                    }

                    &:hover {
                        background: transparent;

                        svg {
                            color: $red;
                        }
                    }
                }

                ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: rgba($body-color, 0.6);
                    font-weight: $font-weight-light;
                }
                ::-moz-placeholder { /* Firefox 19+ */
                    color: rgba($body-color, 0.6);
                    font-weight: $font-weight-light;
                }
                :-ms-input-placeholder { /* IE 10+ */
                    color: rgba($body-color, 0.6);
                    font-weight: $font-weight-light;
                }
                :-moz-placeholder { /* Firefox 18- */
                    color: rgba($body-color, 0.6);
                    font-weight: $font-weight-light;
                }
            }
        }
    }

    &__divider {
        position: relative;
        div {
            position: relative;
            span {
                height: 1px;
                background-color: $body-color;
                position: absolute;
                top: 0px;
                width: calc(100% - 10px);
                right: 0;
                display: block;
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: -10px;
                    border-radius: 50%;
                    border: 10px solid $red;
                }
            }
        }

    }

    &__nav {
        margin-top: 50px;
        a {
            color: $body-color;
            text-decoration: none;
            padding-bottom: 3px;
            &:hover {
                color: $red;
                border-bottom: 1px solid $red;
            }
            &.active:not(.active-trail) {
                color: $red;
                border-bottom: 1px solid $red;
            }

        }

        ul {
            list-style: none;
            display: block;

        }

        li {
            padding: 10px 10px 10px 0;
            display: block;
            text-transform: uppercase;
            font-weight: 700;
            @include media-breakpoint-down(xs) {
                padding-left: 0px;
            }
            a {

            }
            + li {
                /* border-top: 1px solid $color-grey; */
            }
        }

        > ul,
        .language-switch {

            padding: 0 $grid-gutter-width / 2;

            ul {
            }
        }

        > ul {
            padding-left: 0;
            /* border-bottom: 1px solid $color-grey; */
            padding-bottom: 20px;
            > li {
                padding: 10px 0;
                float: none;
                clear: both;
                @include media-breakpoint-down(xs) {

                }
                > ul {
                    > li {
                        float: left;
                    }
                }
            }

            ul {
                padding-left: 0;
                list-style: circle;
                li {
                    padding-top: 15px;
                    display: inline-block;
                    position: relative;
                    margin-right: 20px;
                    text-transform: capitalize;
                    font-weight: 300;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:not(:last-child):not(.section-headline):not(.last):after {
                        content: '';
                        border: 2px solid $blue-light;
                        border-radius: 50%;
                        position: absolute;
                        right: -8px;
                        top: 26px;
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                    &.section-headline {
                        margin-right: 5px;
                        color: $grey-200;
                        font-weight: 500;
                        clear: left;
                    }
                    a {
                        color: $blue-light;
                    }
                }
            }
        }
    }
    .mobile-menu-button {
        top: 30px;
        right: 30px;
        cursor: pointer;
        @include media-breakpoint-down(md) {
            top: 22px;
        }
        svg {
            width: 24px;
            height: 24px;
            path {
                fill: $body-color;
            }
        }

    }
    .overlay-wrapper {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        @include media-breakpoint-down(md) {
            .container {
                max-width: 100%;
            }
        }
    }
    &__skyline {
        display: none;

    }
    .language-switch {
        display: none;
        @include media-breakpoint-down(md) {
            display: inline-block;
        }
        position: relative;
        color: $body-color;
        @media (max-width: 540px) {
            padding-top: 20px;
        }

        ul {
            list-style: none;
            display: inline-block;

            li {
                display: inline-block;
                + li {
                    border-left: 1px solid $body-color;
                    margin-left: 5px;
                    padding-left: 8px;
                }
                a {
                    color: $body-color;
                    text-decoration: none;
                    padding-bottom: 4px;
                    &:hover {
                        color: $red;
                        border-bottom: 1px solid $red;
                    }
                }
                span, span:active {
                    color: $red;
                    font-weight: $font-weight-semibold;
                }
            }
        }
    }
}

body.mobile-menu-open {
    .mobile-navigation {
        transform: translate(0%, 0);

        &__close-helper {
            display: block;
        }
    }
}