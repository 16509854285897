// imports
@import "bricks/accordion";
@import "bricks/cta-advertising";
@import "bricks/teaser";
@import "bricks/tour-teaser";
@import "bricks/news";

.brick {
    padding: $brick-padding 0;
    .brick {
        margin-top: 0;
        margin-bottom: 0;
        padding: $brick-in-brick-padding 0;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    &:last-of-type {

    }

    &__header {
        margin-bottom: $brick-header-bottom-space;
        &--reduced {
            margin-bottom: ($brick-header-bottom-space / 2);
        }
        &--headline,
        &--sub-headline,
        &--info-text {
            &, > * {
                width: 100%;
            }
        }
    }

    &__footer {
        margin-top: $brick-footer-top-space;
        .brick__footer {
            margin-top: ($brick-footer-top-space / 2);

        }
        .cta-button-item {
            &__sub-text {
                margin: 5px 0 0 0;
            }
        }
        &.justify-content-start {
            .cta-button-item {
                margin: 0 10px 0 0;
                text-align: left;
            }
        }
        &.justify-content-center {
            .cta-button-item {
                margin: 0 10px;
                text-align: center;
            }
        }
        &.justify-content-end {
            .cta-button-item {
                margin: 0 0 0 10px;
                text-align: right;
            }
        }
    }

}

.bg-color {
    &--blue {
        background-color: $blue;
        .area-wysiwyg__content {
            color: $white;
            text-align: left;
        }
        .brick__header--headline > h3, .brick__header--headline > h2, .brick__header--headline > h1  {
            color: $white !important;
            margin-bottom: 30px;
            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }

    &--red {
        background-color: $red;
    }

    &--white-grey {
        background: $white-grey;
    }

    &--light-grey {
        background: $grey-light;
    }

    &--grey-skyline {
        @include grey-skyline-background;
    }
}