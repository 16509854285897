.teaser-tour {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white-grey;


    @include media-breakpoint-down(sm) {
        &--slider {
            padding: 10px;

            .teaser-tour__image {
                padding-bottom: 120%;
            }
        }

        &:not(&--slider) {
            background-color: transparent;
        }
    }

    @media (max-width: 380px) {
        &--slider {
            padding: 10px;
        }
    }

    &:hover {
        .teaser-tour__image img {
            transform: scale(1.1);
            @include transition(all 900ms);
            -webkit-filter: blur(3px); /* Safari 6.0 - 9.0 */
            filter: blur(3px);
        }
    }

    &__image {
        position: relative;
        overflow: hidden;
        padding-bottom: 60%;

        @include media-breakpoint-between(md,md) {
            padding-bottom: 80%;
        }

        &__height {
            @include media-breakpoint-up(md) {
                padding-bottom: 100%;
            }

            @include media-breakpoint-between(md,lg) {
                padding-bottom: 120%;
            }
        }

        @include media-breakpoint-between(sm,sm) {
            padding-bottom: 140%;
        }

        @include media-breakpoint-down(sm) {
            padding-bottom: 150%;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            min-width: 100%;
            min-height: 100%;
            transform: scale(1);
            @include transition(all 300ms);
            width: auto;
            height: auto;
            max-width: inherit;
        }

        &__duration {
            color: $body-color;
            text-transform: lowercase;
            position: absolute;
            left: 10px;
            top: 0;
            z-index: 2;

            > span {
                background-color: $white;
                padding: 15px 10px;
                display: inline-block;
                height: 54px;
            }

            svg {
                position: absolute;
                float: left;
                fill: $white;
                left: 0;
                right: 0;
                top: 54px;
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            pointer-events: none;

            &__ {
                background: linear-gradient(0deg, rgba(0,0,0,0.6460959383753502) 0%, rgba(66,66,66,0.8981967787114846) 00%, rgba(255,255,255,0) 62%);
            }
            &__blue {
                background: linear-gradient(0deg, rgba(0,0,0,0.6460959383753502) 0%, rgba(66,66,66,0.8981967787114846) 00%, rgba(255,255,255,0) 62%);
            }
            &__green {
                background: linear-gradient(0deg, rgba(0,0,0,0.6460959383753502) 0%, rgba(66,66,66,0.8981967787114846) 00%, rgba(255,255,255,0) 62%);
            }
        }

        &__text {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 15px 15px 30px 15px;
            z-index: 2;
            color: $white;
            width: 100%;

            &__top-headline {
                font-size: $font-size-24px;
                hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
                @include media-breakpoint-down(md) {
                    font-size: $font-size-24px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: $font-size-22px;
                }

                @include media-breakpoint-down(sm) {
                    font-size: $font-size-26px;
                }
            }
            &__headline {
                hyphens: auto;
                -moz-hyphens: auto;
                -ms-hyphens: auto;
            }

            @media (max-width: 380px) {
                padding: 10px 10px 20px 10px;
            }
        }
    }

    &__callout {
        position: absolute;
        z-index: 1;

        &__big {
            top: -50px;
            right: 50px;

            @include media-breakpoint-between(lg,lg) {
                top: -30px;
                right: 30px;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }

        }

        &__small {
            top: 80px;
            right: 15px;

            @include media-breakpoint-between(sm,sm) {
                top: 50px;
            }

            @include media-breakpoint-down(sm) {
                top: 15px;
            }

        }
        &__top{
            top: -275px;
            right: -110px;
            @media (max-width: 1300px) {
                top: -295px;
                right: -35px;
            }
            @include media-breakpoint-between(md,md) {
                top: -315px;
            }
            @include media-breakpoint-between(sm,sm) {
                top: -360px;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }

        }
    }

    &__content {
        position: relative;

        &-info-icon {
            position: absolute;
            z-index: 2;
            top: -10px;
            right: 10px;
            background-color: $white-grey;
            border-radius: 50%;
            cursor: pointer;

            svg {
                font-size: 20px;
                color: $blue-light;
            }
        }

        &__information {
            padding: 30px 5px;
            height: 100%;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(sm) {
                padding: 30px 0 0 0;
            }

            label {
                width: 100%;
                border-bottom: 1px solid $body-color;
                padding-bottom: 5px;
                font-weight: $font-weight-bold;
            }

            .btn {
                margin-top: auto;
                background: $primary;
                &:hover {
                    background: $blue-light;
                }
            }
        }

        &-images {

        }
    }

    &__prices {
        padding: 15px 0;

        @include media-breakpoint-down(sm) {
            line-height: 1;
        }

        &__item {
            &-txt {
                color: $blue-light;
                font-weight: $font-weight-bold;
                margin-top: 5px;
                display: block;
            }

            svg {
                font-size: 23px;
                display: block;
                margin: 0 auto;
            }
        }

        &__starting-from {
            padding: 0;
        }

        &-row > :first-child {
            padding-right: 0;
        }
    }

    // change styling complete if not slider
    &:not(.teaser-tour__slider) {

        .btn.btn-blue {
            @media (max-width: 380px) {
                line-height: 1.1;
            }
        }

        @include media-breakpoint-down(sm) {
            .btn.btn-blue {
                background-color: transparent;
                padding: 0;
                color: $orange;
                text-transform: inherit;
                text-align: left;
                margin-top: 10px;
            }

            .teaser-tour__content-information {
                text-align: left;
                padding: 5px 0;

                label {
                    border: none;
                    padding: 0;
                    font-weight: $font-weight-regular;
                }
            }

            .teaser-tour__prices {
                padding: 0;
            }
        }
    }
}

.teaser-item.col-md-4 .teaser-tour {
    &__callout {
        &--small {
            @include media-breakpoint-between(md,lg) {
                top: 100px;
            }
        }
    }
}