$interferer-icon: url("../../img/icon-munich-daytrips.svg");
$border-width:3px;
$interferer-background: green;
$interferer-button: $red;

.interferer {
    border: $border-width solid $white;
    background-color: $interferer-background;

    &__iconwrapper {
        background: $interferer-background;
        border-top: $border-width solid $white;
        border-right: $border-width solid $white;
        border-left: $border-width solid $white;
        @include media-breakpoint-down(md){
            height: 50px + $border-width;
        }
    }

    &__icon{
        content:$interferer-icon;
    }

    &__button {
        &:hover {
            background-color:lighten($interferer-button,10);
        }
        background-color:$interferer-button;
    }
}