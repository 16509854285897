#siteWrapper {
    max-width: $site-wrapper-width;
    padding-top: $header-height-desktop;
    margin: 0 auto;
    position: relative;
    overflow-x: hidden;
    &.top-navigation {
        padding-top: $extended-header-height-desktop;
        @include media-breakpoint-down(xs) {
            padding-top: $extended-header-height-mobile;

        }
    }

    .container .container {
        padding-left: 0;
        padding-right: 0;
    }
}

.bg-grey {
    background-color: $white-grey;
}

.fullwidth-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
}


#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    background-color: #cdcdcd!important;
    border-color: #cdcdcd!important;
}


.btn {
    &-primary {
        border-radius: 0;
        background-color: $primary;
        text-transform: uppercase;
        color: $white;
        border: none;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 17px 25px;

        &:hover {
            background-color: $blue-light;
            @include transition(all ease-in-out 300ms);
        }
    }

    &-secondary {
        border-radius: 0;
        background-color: $blue;
        text-transform: uppercase;
        color: $white;
        @include transition(all ease-in-out 300ms);
        font-weight: $font-weight-bold;
        padding: 15px 25px;
        border: 2px solid $white;

        &:hover {
            background-color: $white;
            color: $blue;
            @include transition(all ease-in-out 300ms);
            border: 2px solid $blue;
        }
    }


    &-link {
        font-weight: $font-weight-bold;
        color: $primary;
        padding-left: 25px;
        text-decoration: none;
        background: url("../../img/long-arrow-alt-right-red.svg") no-repeat center left;
        background-size: 20px 20px;
        border: none;
        @include transition(all ease-in-out 300ms);

        &:hover {
            color: $blue-light;
            background: url("../../img/long-arrow-alt-right-blue-light.svg") no-repeat center left;
            background-size: 20px 20px;
            text-decoration: none;
            @include transition(all ease-in-out 300ms);
        }
    }
}


