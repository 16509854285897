.b-teaser {
    position: relative;

    &.brick {
        padding-top: 0;
    }

    &__teaser-items {
        .pimcore_editable_block {
            display: flex;
            flex-wrap: wrap;
        }
        &__twoColumn {
            .pimcore_block_entry {
                width: 50%;
                clear: none;
                padding: 0 15px;
            }
        }
        &__threeColumn {
            .pimcore_block_entry {
                width: 33.3333333333%;
                clear: none;
                padding: 0 15px;
            }
        }
        &__panorama {
            .pimcore_block_entry {
                width: 100%;
                clear: none;
                padding: 0 15px;
            }
        }
    }

    &__items {
        padding: 0;

        // swiper slider styles
        @include media-breakpoint-down(md) {
            .swiper {
                padding: 0 0 50px 0;
                .pagination-wrapper {
                    height: 30px;
                }
                .swiper-button-prev:after, .swiper-button-next:after {
                    color: #fff;
                }
                .swiper-pagination-bullet.swiper-pagination-bullet-active {
                    background: #D30032;
                }
            }
        }

        &__twoColumn {
            .teaser-item:not(:nth-child(1)):not(:nth-child(2)) {
                margin-top: 30px;
            }
        }

/*        &__threeColumn {
            @include media-breakpoint-up(lg) {
                .teaser-item:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                    margin-top: 30px;
                }
            }

            @include media-breakpoint-down(lg) {
                .teaser-item:not(:nth-child(1)):not(:nth-child(2)) {
                    margin-top: 30px;
                }
            }
        }*/

        &__teaser-object {
            height: 100%;
            &__image {

            }
            &__label {

            }
            &__sub-headline {

            }
            &__headline {

            }
            &__text {

            }
            &__link {
            }
        }

        &__masonry {
            margin-left: -15px;
            margin-right: -15px;
            &__teaser-item {
                display: inline-block;
                padding-left: 15px;
                padding-right: 15px;
                margin-top: 0;
                margin-bottom: 15px;
                float: left;
                &__twoColumn {
                    width: 50%;
                }
                &__threeColumn {
                    width: 33.333333%;
                }
            }
        }
    }

    &__advertising-mobile {
        @include media-breakpoint-down(sm) {
            padding-top: 17px;
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}