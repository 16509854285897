@import "../../../../shared/sass/bricks/news";

.b-news {
    font-family: $font-family-base;

    .hidden-items {
        display: none;
    }


    &__headline {
        text-align: start;
        color: $grey-darker;
    }

    &__item {
        &__body {
            padding: 32px;

            &__link {
                a {
                    color: $red;
                }
            }
        }

    }

    &__button {
        color: $blue;
        background-color: white;
        border: 1px solid $blue;
    }
}

section.light-grey {
    background: $grey-light;

    .news-detail {
        &__link-top {
            text-transform: uppercase;
            margin: $brick-padding 0;
        }

        &__content {
            &-headline {
                text-transform: uppercase;
            }
            .h4 {
                text-transform: none;
                font-weight: bold;
            }
            &-images {
                margin: $brick-padding 0;
            }
        }

        &__further-articles {
            h3 {
                text-transform: none;
                color: $grey-dark;
                text-align: center;
            }
            &__article {
                padding: 1.5rem 0;
                border-top: 2px solid $grey;
                &:last-child {
                    border-bottom: 2px solid $grey;
                }
                h3 {
                    text-transform: none;
                    font-weight: bold;
                    color: $grey-darker;
                    text-align: center;
                }
                &__date {
                    color: $grey-dark;
                    margin-bottom: 0.7rem;
                }
                &__headline {
                    font-weight: bold;
                    color: $grey-darker;
                    margin-bottom: 0.7rem;
                }
                &__teaserTextShort {
                    display: flex;
                    margin-bottom: 0.7rem;
                    gap: 5px;
                }
                &__link {
                    text-transform: uppercase;
                }
            }
        }

        &__link-bottom {
            padding: 1.5rem 0;
            border-top: 2px solid $grey;
            text-align: center;
        }
    }
}