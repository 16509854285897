@import "../../../../brands/msr/sass/layout/header";

header {
    &.top-navigation {
        .language-switch {
            ul {
                li {
                    a {
                        &:hover {
                            color: $red;
                            border-bottom: 1px solid $red;
                        }
                    }
                    span, span:active {
                        color: $red;
                    }
                }
            }
        }
    }
}